import axios from "axios";
import { BACKEND_URL } from "../../config"

const SITE_CONFIG_CACHE_KEY = 'site_saved_configs'

export default {
    data: () => ({
       siteConfig: {
           gameMinAmount: 10,
           gameMaxAmount: 100
       }
    }),
    created() {
        const saved = JSON.parse(localStorage.getItem(SITE_CONFIG_CACHE_KEY))

        if (!saved) {
            this.setConfig()
            return
        }

        const expired = new Date(saved.expired)
        if (expired < new Date()) {
            this.setConfig()
            return
        }

        this.siteConfig = saved.config
    },
    methods: {
        async getConfig() {
            const {data} = await axios.get(`${BACKEND_URL}/api/settings`)

            return data
        },
        async setConfig() {
            const config = await this.getConfig()

            let time = new Date()
            time = time.getTime()
            time = time + (10 * 60 * 1000)

            const saveConfig = {
                expired: time,
                config
            }

            localStorage.setItem(SITE_CONFIG_CACHE_KEY, JSON.stringify(saveConfig))

            this.siteConfig = config
        }
    }
}
