import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import WebStart from '../views/WebStart.vue'
import WebQuestions from '../views/WebQuestions.vue'
import Stats from '../views/Stats.vue'
import PageNotFound from '../views/404.vue'
import Rules from "../views/Rules";
import Support from "@/views/Support";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/webquestions',
    component: WebQuestions
  },
  {
    path: '/webstart',
    component: WebStart
  },
  {
    path: '/stats',
    component: Stats
  },
  {
    path: '/rules',
    component: Rules
  },
  {
    path: '/support',
    component: Support
  },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
