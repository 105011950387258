<template>
  <div class="main-layout">
    <div class="container">
      <!-- Header Start -->
      <header class="header">
        <!-- <img
          src="../assets/img/svg/bg.svg"
          alt="Background"
          class="header__bg"
        /> -->
        <div class="logo">
          <img src="../assets/img/svg/logo.svg" alt="Decimal Game" />
        </div>
        <h1>Игра в напёртски на блокчейне Decimal</h1>
      </header>
      <!-- Header End -->

      <!-- Section Hands Start -->
      <section class="hands">
        <div class="wrapper__hands">
          <div class="hands__pair">
            <img
              src="../assets/img/svg/hand-right.svg"
              alt="Right Hand"
              class="hand__right"
            />
            <img
              src="../assets/img/svg/hand-left.svg"
              alt="Left Hand"
              class="hand__left"
            />
          </div>
        </div>
        <p class="section__hands-text">
          Лимиты игры:<br />
          Min: {{ siteConfig.gameMinAmount }} DEL<br />
          Max: {{ siteConfig.gameMaxAmount }} DEL
        </p>
        <div class="section__button">
          <button
            class="section__hands-btn"
            @click="route('/webstart')"
            style="cursor: pointer"
          >
            играть в напёрстки
          </button>
        </div>
      </section>
      <!-- Section Hands End -->

      <!-- Section Rules Start -->
      <section class="rules">
        <div class="wrapper__order">
          <div class="rules__image">
            <img
              class="rules__image-pic"
              src="../assets/img/svg/order.svg"
              alt="Order"
            />
          </div>
          <div class="rules__text">
            <p class="section__rules-text section__rules-text01">
              Игрок должен угадать под каким напёрстком находится шарик и отправить монеты на выбранный им адрес соответствующего напёрстка
            </p>
            <p class="section__rules-text section__rules-text-center">
              В случае выбора напёрстка с шариком вам на кошелёк возвращается ваша ставка в двойном размере!
            </p>
            <p class="section__rules-text section__rules-text03">
              В случае выбора напёрстка без шарика ваша ставка сгорает. Попробуйте снова!
            </p>
          </div>
          <div class="rules__text_below">
            <p class="section__rules-text-below">
              Если вы перевели из вашего кошелька сумму,которая не совпадает с суммой указанной в игре, ваша игра отменяется.  Сумма возвращается автоматически на ваш кошелёк с удержанием комиссии за транзакцию.
            </p>
          </div>
        </div>
      </section>
      <!-- Section Rules End -->

      <site-footer></site-footer>
    </div>
  </div>
</template>

<script>
import SiteFooter from "./SiteFooter";
import api from "../mixins/api";
import siteConfig from "../mixins/siteConfig";

export default {
  name: "MainScreen",
  mixins: [api, siteConfig],
  components: {
    SiteFooter
  },
  methods: {
    route(params) {
      this.$router.push(params);
    },
  },
};
</script>

<style>
.main-layout {
  font-family: "PT Root UI";
  font-size: 12px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
}

/* Header Styles Start */
.logo {
  margin-bottom: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

h1 {
  font-family: 'PT Root UI';
  font-weight: 600;
  font-size: 56px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  line-height: 80px;
}
/* Header Styles End */

/* Section Hands Styles Start */

.hands__pair {
  display: flex;
  justify-content: space-between;
  width: 1410px;
}

.wrapper__hands {
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  justify-content: center;
}

.hand__right {
  padding-right: 16px;
}

.section__hands-text {
  text-align: center;
  opacity: 0.5;
  margin-top: -5px;
  font-weight: 400;
  line-height: 16px;
  padding-bottom: 5px;
}

.section__button {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.section__hands-btn {
  text-transform: uppercase;
  background: #35756b;
  border-radius: 35px;
  border: 0;
  width: 192px;
  height: 60px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}
/* Section Hands Styles End */

/* Section Rules Styles Start */
.wrapper__order {
  max-width: 546px;
  margin: 0 auto;
}

.rules__image {
  display: flex;
  justify-content: center;
}

.rules__image-pic {
  width: 430px;
}

.rules__text {
  display: flex;
  justify-content: space-between;
  width: 546px;
  padding-bottom: 75px;
}
.rules__text_below {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 75px;
}

.section__rules-text {
  width: 154px;
  text-align: center;
  line-height: 16px;
}
.section__rules-text-below {
  text-align: center;
  line-height: 16px;
}

.section__rules-text-center {
  width: 189px;
}

@media (min-width: 1930px) {
  .container {
    height: 100vh;
  }
  .rules {
    height: 40vh;
  }
}

@media (max-width: 1920px) {
  .rules {
    height: 40vh;
  }
}

@media (max-width: 1728px) {
  .rules {
    height: 50vh;
  }
}

@media (max-width: 1410px) {
  /* Hands */
  .container {
    margin: 0 auto;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    width: 990px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .hands__pair img {
    width: 480px;
  }

  .rules__text {
    padding-bottom: 35px;
  }
}

@media (max-width: 1187px) {
  .container {
    margin: 0 auto;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    width: 992px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .hands__pair img {
    width: 480px;
  }
}

@media (max-width: 992px) {
  .container {
    margin: 0 auto;
  }

  h1 {
    font-size: 45px;
  }

  .hands__pair img {
    width: 350px;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    width: 730px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .section__hands-btn {
    width: 400px;
  }
}

@media (max-width: 729px) {
  .container {
    margin: 0 auto;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    max-width: 620px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .hands__pair img {
    max-width: 300px;
    padding-bottom: 20px;
  }

  .rules__image {
    display: none;
  }

  .rules__text {
    width: 395px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .wrapper__order {
    width: 729px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .section__rules-text {
    width: 400px;
  }

  .section__rules-text01 {
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon01.svg) no-repeat top center;
  }

  .section__rules-text03 {
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon03.svg) no-repeat top center;
  }

  .section__rules-text-center {
    margin: 0 auto;
    background: url(../assets/img/svg/rule-icon02.svg) no-repeat top center;
    padding-top: 50px;
  }
}

@media (max-width: 620px) {
  .container {
    margin: 0 auto;
  }

  .wrapper__order {
    width: 334px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .section__rules-text {
    width: 400px;
  }

  .section__rules-text01 {
    padding-bottom: 13px;
    width: 310px;
    margin: 0 auto;
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon01.svg) no-repeat top center;
  }

  .section__rules-text03 {
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon03.svg) no-repeat top center;
  }

  .section__rules-text-center {
    width: 300px;
    margin: 0 auto;
    background: url(../assets/img/svg/rule-icon02.svg) no-repeat top center;
    padding-top: 50px;
  }
}
</style>
