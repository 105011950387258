<template>
  <div class="main-layout">
    <div class="container">
      <!-- Header Start -->
      <header class="header">
        <div class="logo">
          <img src="../assets/img/svg/logo.svg" alt="Decimal Game" />
        </div>
      </header>

      <section class="text-wrapper">
        <h2>Как играть в напёрстки?</h2>
        <p>Для игры в напёрстки вам необходимо создать кошелек DecimalChain
          и приобрести криптовалюту Del. Подробней про DecimalChain.</p>
        <div class="img_references">
          <a class="google_badge_ref" href="https://play.google.com/store/apps/details?id=com.chain.decimal">
            <img class="google_badge" src="../assets/img/google-play-badge.png" alt="Ссылка на DecimalChain в Google Play">
          </a>
          <a class="apple_badge_ref" href="https://apps.apple.com/us/app/id1510802073">
            <img class="apple_badge" src="../assets/img/AppleBadge.svg" alt="Ссылка на DecimalChain в App Store">
          </a>
        </div>

        <p>Как сделать ставку?</p>

        <ul class="list">
          <li>
            <p>Нажать кнопку «ИГРАТЬ В НАПЁРСТКИ»</p>
          </li>
          <li>
            <p>Нажать кнопку «ВРАЩАТЬ НАПЁРСТКИ»</p>
          </li>
          <li>
            <p>Сделать выбор напёрстка с шариком и отправить из вашего кошелька необходимую сумму Del с помощью адреса или QR-кода  расположенными рядом с напёрстком </p>
          </li>
          <li>
            <p>После осуществления перевода Del через 2-3 минуты система покажет вам под каким напёрстком был спрятан шарик</p>
          </li>
        </ul>

        <p>Если Вы выбрали напёрсток с шариком, система автоматически отправит обратно на ваш кошелёк сумму вашей ставки в ДВОЙНОМ размере за вычетом комиссии на транзакцию.
          При выигрыше вознаграждение моментально переводится к вам на кошелёк.
        </p>

        <h2>Почему игра создана на блокчейне?</h2>
        <p>Благодаря консенсусу доверия и открытости системы, которые реализованы в блокчейне, игра работает максимально честно для пользователя. Любой может отследить свои транзакции в обозревателе.</p>
      </section>

      <site-footer></site-footer>
    </div>
  </div>
</template>

<script>
import SiteFooter from "../components/SiteFooter";

export default {
  name: "Rules",
  components: {
    SiteFooter
  },
}
</script>

<style scoped>

.main-layout {
  font-family: "PT Root UI";
  font-size: 12px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
}

/* Header Styles Start */
.logo {
  margin-bottom: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

h1 {
  font-family: 'PT Root UI';
  font-weight: 600;
  font-size: 56px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  line-height: 80px;
}
/* Header Styles End */

/* Section Hands Styles Start */

.hands__pair {
  display: flex;
  justify-content: space-between;
  width: 1410px;
}

.wrapper__hands {
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  justify-content: center;
}

.hand__right {
  padding-right: 16px;
}

.section__hands-text {
  text-align: center;
  opacity: 0.5;
  margin-top: -5px;
  font-weight: 400;
  line-height: 16px;
  padding-bottom: 5px;
}

.section__button {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.section__hands-btn {
  text-transform: uppercase;
  background: #35756b;
  border-radius: 35px;
  border: 0;
  width: 192px;
  height: 60px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}
/* Section Hands Styles End */

/* Section Rules Styles Start */
.wrapper__order {
  max-width: 546px;
  margin: 0 auto;
}

.rules__image {
  display: flex;
  justify-content: center;
}

.rules__image-pic {
  width: 430px;
}

.rules__text {
  display: flex;
  justify-content: space-between;
  width: 546px;
  padding-bottom: 75px;
}

.section__rules-text {
  width: 154px;
  text-align: center;
  line-height: 16px;
}

.section__rules-text-center {
  width: 189px;
}

@media (min-width: 1930px) {
  .container {
    height: 100vh;
  }
  .rules {
    height: 40vh;
  }
}

@media (max-width: 1920px) {
  .rules {
    height: 40vh;
  }
}

@media (max-width: 1728px) {
  .rules {
    height: 50vh;
  }
}

@media (max-width: 1410px) {
  /* Hands */
  .container {
    margin: 0 auto;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    width: 990px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .hands__pair img {
    width: 480px;
  }

  .rules__text {
    padding-bottom: 35px;
  }
}

@media (max-width: 1187px) {
  .container {
    margin: 0 auto;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    width: 992px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .hands__pair img {
    width: 480px;
  }
}

@media (max-width: 992px) {
  .container {
    margin: 0 auto;
  }

  h1 {
    font-size: 45px;
  }

  .hands__pair img {
    width: 350px;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    width: 730px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .section__hands-btn {
    width: 400px;
  }
}

@media (max-width: 729px) {
  .container {
    margin: 0 auto;
  }

  .hands__pair {
    display: flex;
    justify-content: space-between;
    max-width: 620px;
    margin: 0 auto;
  }

  .wrapper__hands {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .hands__pair img {
    max-width: 300px;
    padding-bottom: 20px;
  }

  .rules__image {
    display: none;
  }

  .rules__text {
    width: 395px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .wrapper__order {
    width: 729px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .section__rules-text {
    width: 400px;
  }

  .section__rules-text01 {
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon01.svg) no-repeat top center;
  }

  .section__rules-text03 {
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon03.svg) no-repeat top center;
  }

  .section__rules-text-center {
    margin: 0 auto;
    background: url(../assets/img/svg/rule-icon02.svg) no-repeat top center;
    padding-top: 50px;
  }
}

@media (max-width: 620px) {
  .container {
    margin: 0 auto;
  }

  .wrapper__order {
    width: 334px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .section__rules-text {
    width: 400px;
  }

  .section__rules-text01 {
    padding-bottom: 13px;
    width: 310px;
    margin: 0 auto;
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon01.svg) no-repeat top center;
  }

  .section__rules-text03 {
    padding-top: 50px;
    background: url(../assets/img/svg/rule-icon03.svg) no-repeat top center;
  }

  .section__rules-text-center {
    width: 300px;
    margin: 0 auto;
    background: url(../assets/img/svg/rule-icon02.svg) no-repeat top center;
    padding-top: 50px;
  }
}

.text-wrapper {
  max-width: 60vw;
  margin: auto;
  padding-bottom: 160px;
}

.text-wrapper p {
  font-size: 25px;
  color: #d7d7d7;
  padding-bottom: 10px;
  text-indent: 30px;
  text-align: justify;
}

.list {
  padding: 20px 60px;
}

.list li {
  list-style: disc;
}

h2 {
  font-size: 35px;
  margin: 30px 0;
}

@media (max-width: 500px) {
  .text-wrapper {
    max-width: 90vw;
    margin: auto;
  }

  .text-wrapper p {
    font-size: 20px;
    text-indent: 15px;
  }

  .list {
    padding: 20px 30px;
  }

  h2 {
    font-size: 28px;
    margin: 15px 0;
  }
}
.img_references{
  display: flex;
  justify-content: space-evenly;
}
.google_badge{
  width: 200px;
}

.apple_badge{
  width: 200px;
  padding: 10px;
}

.google_badge_ref{
  display: flex;
  align-items: center;
}

.apple_badge_ref{
  display: flex;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .apple_badge {
    width: 186px;
  }

  .google_badge{
    width: 204px;
  }
}
@media screen and (max-width: 450px) {
  .apple_badge {
    width: 150px;
  }

  .google_badge{
    width: 143px;
  }
}
</style>
