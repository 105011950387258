import axios from "axios";
import { BACKEND_URL } from "../../config"

export default {
    methods: {
        async createGame() {
            const {data} = await axios.post(`${BACKEND_URL}/api/games`)

            return data
        },
        async getGame(id) {
            const {data} = await axios.get(`${BACKEND_URL}/api/games/${id}`, {
                headers: {
                    accept: 'application/json'
                }
            })

            return data
        },
        async loadGames(page = 0) {

            const query = `?page=${page}`

            const {data} = await axios.get(`${BACKEND_URL}/api/games${query}`, {
                headers: {
                    accept: 'application/json'
                }
            })

            return data
        }
    }
}
