<template>
  <div class="main-layout">
    <div class="container">
      <header class="header">
        <div class="header__logo">
          <img
            class="header__logo-pic"
            src="../assets/img/svg/logo.svg"
            alt="Logo"
          />
        </div>
      </header>
      <div class="header__title">
        <h1 class="header__title-text">Часто задаваемые вопросы</h1>
      </div>
      <main class="main">
        <button class="accordion up" @click="openPanel($event)">
          Заголовок
        </button>
        <div class="panel">
          <p>Описание</p>
        </div>
        <button class="accordion" @click="openPanel($event)">Заголовок</button>
        <div class="panel">
          <p>
            Описание
            <!-- 1. Обязательное условие – платный хостинг и домен не ниже второго
              уровня. <br />
              2. Все типы технических работ должны быть выполнены в четко
              обговоренные сроки. <br />
              3. Особое внимание необходимо уделить контенту. Недопустимо
              присутствие пустых или неработающих структурных элементов сайта,
              все страницы должны быть заполнены. <br /> -->
          </p>
        </div>

        <button class="accordion" @click="openPanel($event)">Заголовок</button>
        <div class="panel">
          <p>Описание</p>
        </div>
        <button class="accordion" @click="openPanel($event)">Заголовок</button>
        <div class="panel">
          <p>Описание</p>
        </div>
        <button class="accordion" @click="openPanel($event)">Заголовок</button>
        <div class="panel">
          <p>Описание</p>
        </div>

        <button class="accordion down" @click="openPanel($event)">
          Заголовок
        </button>
        <div class="panel">
          <p>Описание</p>
        </div>
      </main>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from "../components/SiteFooter";

export default {
  name: 'WebQuestions',
  components: {
    SiteFooter
  },
  methods: {
    route(params) {
      this.$router.push(params);
    },
    openPanel(event) {
      var panel = event.target.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
  },
};
</script>

<style scoped>
.main {
  display: grid;
  justify-content: center;
  margin-bottom: 200px;
}

.header__logo {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.header__title-text {
  font-family: "Cony";
  font-weight: 600;
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 30px;
}

.accordion {
  background-color: #051430;
  border: 1px solid #374359;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 960px;
  text-align: left;
  outline: none;
  background: url("../assets/img/webquestions/+.svg") 900px 15px no-repeat;
  transition: 0.4s;
}
/*
.up {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.down {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
} */

.panel {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 60px;
  line-height: 16px;
  background-color: #051430;
  border: 1px solid #374359;
  color: #fff;
  display: none;
  overflow: hidden;
  width: 960px;
}

@media (max-width: 1300px) {
  .accordion {
    width: 800px;
    background: url("../assets/img/webquestions/+.svg") 740px 15px no-repeat;
  }
  .panel {
    width: 800px;
  }
}
@media (max-width: 1024px) {
  .accordion {
    width: 800px;
    background: url("../assets/img/webquestions/+.svg") 740px 15px no-repeat;
  }
  .panel {
    width: 800px;
  }
}
@media (max-width: 768px) {
  .accordion {
    width: 700px;
    background: url("../assets/img/webquestions/+.svg") 640px 15px no-repeat;
  }
  .panel {
    width: 700px;
  }
}
@media (max-width: 425px) {
  .container {
    width: 100%;
  }
  .accordion {
    width: 360px;
    background: url("../assets/img/webquestions/+.svg") 300px 15px no-repeat;
  }
  .panel {
    width: 360px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 767px;
    margin: 0 auto;
    padding-left: 20px;
  }

  .header {
    max-width: 450px;
    margin: 0 auto;
  }

  .header__logo {
    width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .header__title {
    width: 450px;
    margin: 0 auto;
  }

  .header__title-text {
    width: 248px;
    white-space: nowrap;
    font-size: 30px;
    padding-left: 15px;
  }
}

@media screen and (min-width: 360px) {
  .header__title-text {
    text-align: center;
  }
}
</style>
