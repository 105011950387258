<template>
  <div>
    <MainScreen/>
  </div>
</template>

<script>
import MainScreen from '@/components/MainScreen.vue'

export default {
  components: {
    MainScreen
  }
}
</script>
