<template>
  <div class="main-layout">
    <div class="wrapper">
      <div class="container">
        <header class="header">
          <img
            src="@/assets/img/svg/bg.svg"
            alt="Background"
            class="header__bg"
          />
          <div class="logo">
            <img src="@/assets/img/svg/logo.svg" alt="Decimal Game" />
          </div>
          <h1>ОШИБКА 404</h1>
        </header>
        <main class="main">
          <div class="main-404__wrapper">
            <img
              src="@/assets/img/404/Frame.svg"
              alt="404-image"
              class="main-404__image"
            />
            <p class="main-404__text">
              Указаной страницы не существует или адресс введен некорректно.
              <br />
              Пожалуйста проверьте адресною строку.
            </p>
            <router-link to="/" class="main-404__btn">ПЕРЕЙТИ НА ГЛАВНУЮ</router-link>
          </div>
        </main>
      </div>
      <site-footer></site-footer>
    </div>
  </div>
</template>

<script>
import SiteFooter from "../components/SiteFooter";

export default {
  name: '404',
  components: {
    SiteFooter
  },
  methods: {
    route(params) {
      this.$router.push(params);
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 1410px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
  overflow: hidden;
}

/* Header Styles Start */
.logo {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.header__bg {
  position: absolute;
  pointer-events: none;
  margin-top: -23px;
}

h1 {
  font-family: "Cony";
  font-weight: 600;
  font-size: 56px;
  text-transform: uppercase;
  text-align: center;
}
/* Header Styles End */

.main-404__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.main-404__image {
  display: block;
  margin-top: 60px;
}
.main-404__text {
  display: block;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #fff;
  font-size: 14px;
  line-height: 143%;
}
.main-404__btn {
  background: #35756b;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-right: 20px;
  padding-left: 26px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  border-radius: 60px;
  margin-bottom: 30px;
}

@media (max-width: 1410px) {
  /* Hands */
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .header__bg {
    display: none;
  }

  .hands__pair img {
    width: 480px;
  }
}

@media (max-width: 1187px) {
  .container {
    max-width: 992px;
    margin: 0 auto;
  }

  .hands__pair img {
    width: 480px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 750px;
    margin: 0 auto;
  }

  h1 {
    font-size: 45px;
  }

  .hands__pair img {
    width: 350px;
  }
}

@media (max-width: 729px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }

  .hands__pair img {
    max-width: 300px;
    padding-bottom: 20px;
  }
}

@media (max-width: 620px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }

  .main-404__image {
    width: 280px;
    height: 148px;
  }
}
</style>
