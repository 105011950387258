<template>
  <div class="container__footer">
    <footer class="footer">
      <div class="wrapper__footer">
        <div class="footer__links">
          <router-link to="/rules" class="footer__link footer__link01">Правила и оферта</router-link>
          <router-link to="/webquestions"
             class="footer__link footer__link02">ВОПРОС ОТВЕТ</router-link>
          <router-link to="/stats" class="footer__link footer__link03">Статистика игроков</router-link>
          <router-link to="/" class="footer__link footer__link04">Играть в напёрстки</router-link>
          <router-link to="/support" class="footer__link footer__link05">Поддержка</router-link>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "SiteFooter"
}
</script>

<style scoped>
.container__footer {
  max-width: min(1920px, calc(100vw - 10px));
  border-top: 1px solid #374359;
  margin: 80px 0 0 0;
}

.wrapper__footer {
  padding: 24px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 615px;
}

.footer__link {
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding-left: 23px;
}

.footer__link:hover {
  opacity: 0.65;
  transition: 0.2s;
}

.footer__link01 {
  background: url(../assets/img/svg/footer-icon01.svg) no-repeat left center;
}

.footer__link02 {
  background: url(../assets/img/svg/footer-icon02.svg) no-repeat left center;
}

.footer__link03 {
  background: url(../assets/img/svg/footer-icon03.svg) no-repeat left center;
}

.footer__link04 {
  background: url(../assets/img/svg/footer-icon04.svg) no-repeat left center;
}

.footer__link05 {
  background: url(../assets/img/svg/footer-icon05.svg) no-repeat left center;
  background-size: contain;
}

.footer__logo {
  display: flex;
  justify-content: space-between;
}

.footer__logo01 {
  display: flex;
  justify-content: space-between;
  width: 100px;
  align-items: center;
  padding-right: 20px;
}

.footer__logo02 {
  display: flex;
  justify-content: space-between;
  width: 100px;
  align-items: center;
  margin-left: 25px;
}

.footer__logo-text {
  color: #fff;
  opacity: 0.5;
  padding-left: 10px;
}

@media (max-width: 992px) {
  .container__footer {
    margin: 0 auto;
  }
}

@media (max-width: 729px) {

  .wrapper__footer {
    padding: 24px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding: 0;
  }

  .footer__link {
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    padding: 5px 0 5px 23px;
  }

  .footer {
    width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 620px) {
  .footer {
    max-width: 360px;
    margin: 0 auto;
  }

  .wrapper__footer {
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
  }

  .footer__links {
    display: flex;
    flex-direction: column;
    width: 215px;
    padding: 0 100px 0 10px;
  }

  .footer__link {
    white-space: nowrap;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    padding: 5px 0 5px 28px;
  }

  .footer__logo {
    display: block;
  }

  .footer__logo01 {
    padding-bottom: 5px;
  }
}
</style>
