<template>
  <div v-show="value" class="modal-container" @click="close">
    <div class="modal" @click.stop="">
      <div class="modal-close" @click="close">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteModal",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped>
.modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  min-width: 300px;
  min-height: 200px;
  padding: 0 30px;
  background-color: #283D77;
  border-radius: 20px;
  position: relative;
}

.modal-close {
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.modal-content {
  padding-top: 60px;
}
</style>
