<template>
  <div class="main-layout">
      <!-- Header Start -->
    <header class="header">
      <div class="logo">
        <img src="../assets/img/svg/logo.svg" alt="Decimal Game" />
      </div>
    </header>

      <div class="body">
        <div class="text-wrapper">
          <p>По всем вопросам обращаться в поддержку</p>
          <a href="https://t.me/noootimetorush">@noootimetorush</a>
        </div>
      </div>
      <div class="footer">
      </div>
    </div>
</template>

<script>
export default {
  name: "Support"
}
</script>

<style scoped>
.main-layout {
  display: grid;
  grid-template: "header" "body" "footer";
  grid-template-rows: auto;
  width: 100%;
  height: 100vh;
}

.main-layout > div {
  flex: 1;
}

.header{
  grid-area: header;
}

.body{
  grid-area: body;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

a:link {
  color: coral;
}
a:visited {
  color: white;
}
a:hover {
  text-decoration: underline;
}
a:active {
  color: DarkSalmon ;
}
.footer{
  grid-area: footer;
}

.text-wrapper{
  font-size: 30px;
}

</style>