<template>
  <div class="main-layout">
    <div class="wrapper"></div>
    <div class="container">
      <header class="header">
        <div class="header__logo">
          <img
              class="header__logo-pic"
              src="../assets/img/svg/logo.svg"
              alt="Logo"
          />
        </div>
        <h1 class="header__title">угадайте правильный напёрсток</h1>
        <div :style="{maxHeight: show ? '100vh' : 0, overflow: 'hidden', transition: 'all .6s'}">
          <div class="header__subtitle-block">
            <p class="header__subtitle">
              Пополните один из кошельков на сумму от {{ siteConfig.gameMinAmount }} DEL до {{
                siteConfig.gameMaxAmount
              }} DEL
            </p>
          </div>
          <p class="header__subtitle-text">
            При начислении ставки напёрсток откроется автоматически. Ставка оплачивается до конца сессии, после окончания сессии игра аннулируется.
          </p>
        </div>
      </header>
      <div class="timer" :style="{maxHeight: show ? '100vh' : 0, overflow: 'hidden', transition: 'all .6s'}">
        <p class="timer__text">{{ timer }}</p>
      </div>
      <main class="main">
        <div :class="['qr', {qrShow: showQR}]" :style="{display: showQR ? 'flex' : 'none'}">
          <div class="qrWrapper">
            <canvas ref="qr1" class="qr__img"/>
            <canvas ref="qr2" class="qr__img"/>
            <canvas ref="qr3" class="qr__img"/>
          </div>
        </div>
        <div class="btn__copy" :style="{maxHeight: show ? '100vh' : 0, overflow: 'hidden', transition: 'all .6s'}">
          <button class="copy__address" :data-clipboard-text="game && game.wallet_one.address">
            <span class="copy__address-text">скопировать адрес кошелька №1</span>
          </button>
          <button class="copy__address" :data-clipboard-text="game && game.wallet_two.address">
            <span class="copy__address-text">скопировать адрес кошелька №2</span>
          </button>
          <button class="copy__address" :data-clipboard-text="game && game.wallet_three.address">
            <span class="copy__address-text">скопировать адрес кошелька №3</span>
          </button>
        </div>
        <section class="images">
          <div class="images__cups__000">
            <div class="images__cups">
              <img
                  v-if="!defeat && !win"
                  id="box1"
                  class="images__cup images__cup-1"
                  src="../assets/img/svg/cup.svg"
                  alt="Cup"
              />
              <img
                  v-if="!defeat && !win"
                  id="box2"
                  class="images__cup images__cup-2"
                  src="../assets/img/svg/cup.svg"
                  alt="Cup"
              />
              <img
                  v-if="!defeat && !win"
                  id="box3"
                  class="images__cup images__cup-3"
                  src="../assets/img/svg/cup.svg"
                  alt="Cup"
              />
            </div>
          </div>
          <div class="images__glow">
            <img
                class="images__glow-pic"
                src="../assets/img/svg/bg-glow.svg"
                alt="Glow"
            />
          </div>
          <div class="images__ball" :class="[ballClass]">
            <img
                v-if="!defeat && !win"
                class="images__ball-pic"
                src="../assets/img/svg/ball.svg"
                alt="Ball"
            />
          </div>
        </section>
        <section v-if="showTransferInfo" class="section_transfer_info">
          <div class="section_transfer_info_text">
            <p>Укажите в комментарии адрес кошелька для начисления выигрыша</p>
          </div>
        </section>
        <section class="amount">
          <div class="amount__input">
            <div class="amount__input-field">
              <p class="amount__text">
                Min: {{ siteConfig.gameMinAmount }} DEL<br />
                Max: {{ siteConfig.gameMaxAmount }} DEL</p>
            </div>
            <div class="amount__button">
              <button
                  class="amount__btn"
                  @click="start()"
                  :disabled="disabled"
                  style="cursor: pointer"
              >
                <span class="amount__btn-text">вращать напёрстки</span>
              </button>
            </div>
          </div>
        </section>
      </main>
      <site-footer></site-footer>
      <div class="popup" v-if="defeat">
        <div class="popup__body">
          <div class="popup__content">
            <a
                class="popup__close"
                style="cursor: pointer"
                @click="defeat = false"
            >
              <img src="@/assets/img/close.svg" alt="close"/>
            </a>
            <div class="popup__title-win">поражение</div>
            <div class="popup__img-win">
              <img src="@/assets/img/svg/defeat.svg" alt="defeat-img"/>
            </div>
            <div class="popup-button">
              <button class="btn" @click="defeat = !defeat">Играть еще</button>
            </div>
          </div>
        </div>
      </div>
      <div class="popup" v-if="adjourned">
        <div class="popup__body">
          <div class="popup__content">
            <a
                class="popup__close"
                style="cursor: pointer"
                @click="adjourned = false"
            >
              <img src="@/assets/img/close.svg" alt="close"/>
            </a>
            <div class="popup__title-win">Отмена</div>
            <div class="popup__img-win font__for-adjourned">
              Не корректный адрес в комментарии транзакции. Пожалуйста обратитесь в <a class="font__for-adjourned_link" href="/support">поддержку</a>
            </div>
            <div class="popup-button">
              <button class="btn" @click="defeat = !defeat">Играть еще</button>
            </div>
          </div>
        </div>
      </div>
      <div class="popup" v-if="win">
        <div class="popup__body">
          <div class="popup__content">
            <a class="popup__close" style="cursor: pointer" @click="win = false">
              <img src="@/assets/img/close.svg" alt="close"/>
            </a>
            <div class="popup__title-win">ПОБЕДА!</div>
            <div class="popup__img-win">
              <img src="@/assets/img/2xWin.png" alt="2xWin"/>
            </div>
            <div class="popup-button">
              <button class="btn" @click="win = false">Играть еще</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="notifications">
      <div v-for="(notification, indx) in notifications" :key="indx" class="notification">
        {{ notification }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "../mixins/api";
import SiteFooter from "../components/SiteFooter.vue";
import siteConfig from "../mixins/siteConfig";
import ClipboardJS from "clipboard";

const QRCode = require('qrcode');

export default {
  mixins: [api, siteConfig],
  components: {
    SiteFooter
  },
  data: () => ({
    notifications: [],
    notificationTimer: null,
    disabled: false,
    defeat: false,
    win: false,
    adjourned: false,
    show: false,
    showQR: false,
    showTransferInfo: false,
    game: null,
    ballPosition: 2,
    timer: null
  }),
  computed: {
    ballClass() {
      return this.ballPosition === 2 ? 'center'
          : this.ballPosition === 1 ? 'left'
              : 'right';
    }
  },
  mounted() {
    this.game = JSON.parse(localStorage.getItem('currentGame'))

    if (
        this.game &&
        this.game.expired_at &&
        new Date(this.game.expired_at) > new Date()
    ) {
      this.disabled = true
      this.makeQR()
      this.startTimer()
      this.startChecker()
      this.show = true
    }

    const copier = new ClipboardJS('.copy__address')
    copier.on('success', () => {
      this.sendInfo('Скопировано')
    })
  },
  methods: {
    makeQR() {
      QRCode.toCanvas(
          this.$refs.qr1,
          this.game.wallet_one.address,
          {
            width: 80,
            height: 80,
          }
      )

      QRCode.toCanvas(
          this.$refs.qr2,
          this.game.wallet_two.address,
          {
            width: 80,
            height: 80,
          }
      )

      QRCode.toCanvas(
          this.$refs.qr3,
          this.game.wallet_three.address,
          {
            width: 80,
            height: 80,
          }
      )
    },
    sendInfo(text) {
      clearTimeout(this.notificationTimer)
      this.notifications = []

      setTimeout(() => {
        this.notifications = [text]
        this.notificationTimer = setTimeout(() => {
          this.notifications = this.notifications.slice(1)
        }, 5000)
      }, 30)
    },

    CorectionMove(coordX) {
      if ((coordX >= -50) && (coordX <= 50)) coordX = 0;
      if ((coordX >= 140) && (coordX <= 250)) coordX = 193;
      if ((coordX >= 320) && (coordX <= 480)) coordX = 386;
      if ((coordX >= -260) && (coordX <= -130)) coordX = -193;
      if ((coordX >= -460) && (coordX <= -310)) coordX = -386;
      return coordX;
    },
    CorectionMoveMobile(coordX) {
      let screenWidth = window.screen.width;
      let move = 193; //Расстояние сдвига
      if (screenWidth < 518) {
        //Адаптивное свойство
        move = parseInt(((screenWidth - 320) / (500 - 320) * (184 - 120) + 120) - 30);
      }

      if (coordX >= -50 && coordX <= 50) coordX = 0;
      else if (coordX >= move - 50 && coordX <= move + 50) coordX = move;
      else if (coordX >= 2 * move - 50 && coordX <= 2 * move + 50) coordX = 2 * move;
      else if (coordX >= -move - 50 && coordX <= -move + 50) coordX = -move;
      else if (coordX >= -2 * move - 50 && coordX <= -2 * move + 50) coordX = -2 * move;

      return coordX;
    },

    MRight(box, SpeedBox) {
      let screenWidth = window.screen.width;
      let move = 193; //Расстояние сдвига

      if (screenWidth < 518) {
        //Адаптивное свойство
        move = ((screenWidth - 320) / (500 - 320) * (184 - 120) + 120) - 30;
      }

      let additionalNumber = 30;
      var coordX = box.style.left; //Узнаем left
      if (!coordX) coordX = "0px"; //Если coordX = 0 тогда + px
      coordX = coordX.substr(0, coordX.length - 2); //Обрезаем px
      coordX = Number.parseInt(coordX); //Превращаем строку в число
      let i = coordX;
      let time = setInterval(() => {
        if (coordX >= move + i) {
          clearInterval(time);
          //сдвигаю наперсток на правильное место
          if (screenWidth < 518) {
            coordX = this.CorectionMoveMobile(coordX);
          } else {
            coordX = this.CorectionMove(coordX);
          }
          setTimeout(() => {
            box.style.left = coordX + "px";
          }, 190);
        } else {
          //Двигаем наперсток
          coordX = coordX + SpeedBox + additionalNumber;
          box.style.left = coordX + "px";
        }
      }, 10);
    },
    MLeft(box, SpeedBox) {
      let screenWidth = window.screen.width;
      let move = 193; //Расстояние сдвига

      if (screenWidth < 518) {
        //Адаптивное свойство
        move = ((screenWidth - 320) / (500 - 320) * (184 - 120) + 120) - 30;
      }

      let additionalNumber = 30;
      var coordX = box.style.left; //Узнаем left
      if (!coordX) coordX = "0px"; //Если coordX = 0 тогда + px
      coordX = coordX.substr(0, coordX.length - 2); //Обрезаем px
      coordX = Number.parseInt(coordX); //Превращаем строку в число
      let i = coordX;
      let time = setInterval(() => {
        if (coordX <= i - move) {
          clearInterval(time);
          //сдвигаю наперсток на правильное место
          if (screenWidth < 518) {
            coordX = this.CorectionMoveMobile(coordX);
          } else {
            coordX = this.CorectionMove(coordX);
          }
          setTimeout(() => {
            box.style.left = coordX + "px";
          }, 190);
        } else {
          //Двигаем наперсток
          coordX = coordX - SpeedBox - additionalNumber;
          box.style.left = coordX + "px";
        }
      }, 10);
    },
    MoveUp(nbox) {
      let box = document.querySelector(".images__cup-" + nbox); // наперсток
      box.style.top = "-100px";
    },
    //Вернуть стакан
    MoveDown(nbox) {
      let box = document.querySelector(".images__cup-" + nbox); // наперсток
      box.style.top = "0px";
    },

    S0() {
      let ball = document.querySelector(".images__ball-pic"); //Шар
      let box1 = document.querySelector(".images__cup-1"); //первый наперсток
      let box2 = document.querySelector(".images__cup-2"); //Второй наперсток
      let box3 = document.querySelector(".images__cup-3"); //Третий наперсток

      let SpeedBox = 15; //скорость

      //1-3-2
      ball.style.left = "0px";
      //  MOVE 1  1-2-3  2-3-1 //
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 1000);
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 1350);
      //  MOVE 2  2-3-1  3-2-1  3-1-2//
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 1700);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 2050);
      //  MOVE 3  3-1-2  1-3-2  1-2-3//
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 2400);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 2750);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 3100);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 3450);

      //  MOVE 1  1-2-3  2-3-1 //
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 3800);
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 4150);
      //  MOVE 2  2-3-1  3-2-1  3-1-2//
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 4500);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 4850);
      //  MOVE 3  3-1-2  1-3-2  1-2-3//
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 5100);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 5450);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 5800);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 6150);

      //  MOVE 1  1-2-3  2-3-1 //
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 6500);
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 6850);
      //  MOVE 2  2-3-1  3-2-1  3-1-2//
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 7100);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 7450);
      //  MOVE 3  3-1-2  1-3-2  1-2-3//
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 7800);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 8150);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 8500);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 8850);

      //  MOVE 1  1-2-3  2-3-1 //
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 9100);
      setTimeout(() => {
        this.MRight(box1, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 9450);
      //  MOVE 2  2-3-1  3-2-1  3-1-2//
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 9800);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 10150);
      //  MOVE 3  3-1-2  1-3-2  1-2-3//
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box1, SpeedBox);
      }, 10500);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 10850);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 11100);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 11450);
      setTimeout(() => {
        this.MRight(box2, SpeedBox);
        this.MLeft(box3, SpeedBox);
      }, 11800);
      setTimeout(() => {
        this.MRight(box3, SpeedBox);
        this.MLeft(box2, SpeedBox);
      }, 12150);

      setTimeout(() => {
        ball.style.opacity = "1";
      }, 13500);
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    moveRight() {
      let render = 0;

      //Получаем в переменные напёрстки и шар
      let box1 = document.querySelector(".images__cup-1"); //первый наперсток
      let box2 = document.querySelector(".images__cup-2"); //Второй наперсток
      let box3 = document.querySelector(".images__cup-3"); //Третий наперсток
      let ball = document.querySelector(".images__ball-pic"); //Шар

      if (render > 0) {
        //СТАРТ ИГРЫ
        this.MoveDown("1");
        this.MoveDown("2");
        this.MoveDown("3");
        ball.style.left = "0px";
        setTimeout(() => {
          box1.style.left = "0px";
          box2.style.left = "0px";
          box3.style.left = "0px";
        }, 500);
        this.MoveUp("2");
      } else {
        render++;
      }
      ball.style.opacity = "0"; //шарик сделать не видимым
      //СТАРТ ИГРЫ
      this.MoveDown("1");
      this.MoveDown("2");
      this.MoveDown("3");

      ball.style.left = '0px';
      ball.style.opacity = "0"; //шарик сделать не видимым
      setTimeout(() => {
        box1.style.left = "0px";
        box2.style.left = "0px";
        box3.style.left = "0px";
      }, 500);

      this.S0();

      //Кнопка снова активна и только теперь можно кликать по наперсткам
      setTimeout(() => {
        this.show = true;
      }, 12500);
      setTimeout(() => {
        this.showQR = true;
        this.showTransferInfo = true;
      }, 12700);
    },

    async showBallAndHide() {
      await new Promise((ok) => {
        this.ballPosition = 2
        this.MoveUp(2)
        setTimeout(ok, 3500)
      })

      await new Promise((ok) => {
        this.MoveDown(2)
        setTimeout(ok, 1300)
      })
    },

    timeout(time) {
      return new Promise(ok => {
        setTimeout(ok, time)
      })
    },

    async start() {

      this.show = false
      await this.timeout(600)
      this.showQR = false
      this.showTransferInfo = false


      this.disabled = true;

      const [game] = await Promise.all([
        this.createGame(),
        this.showBallAndHide()
      ])

      this.game = game

      localStorage.setItem('currentGame', JSON.stringify(game))

      this.moveRight();

      this.startTimer()
      this.startChecker()

      this.makeQR()
    },
    startChecker() {
      setTimeout(() => {
        this.getGame(this.game.id).then((game) => {
          if (game.status === 'adjourned') {
            this.showQR = false;
            this.showTransferInfo = false;

            this.game = null;
            this.show = false;
            this.disabled = false
            this.showQR = false;
            this.showTransferInfo = false;

            this.adjourned = true;
            localStorage.removeItem('currentGame')

            return;
          }

          if (game.status !== 'finished') {
            this.startChecker();
          } else {
            const winWalletNumber =
                game.win_wallet === game.wallet_1
                    ? 1 : game.win_wallet === game.wallet_2 ?
                    2 : 3;
            const selectedWalletNumber =
                game.selected_wallet === game.wallet_1
                    ? 1 : game.selected_wallet === game.wallet_2 ?
                    2 : 3;

            this.ballPosition = winWalletNumber;
            this.showQR = false;
            this.showTransferInfo = false;
            this.MoveUp(winWalletNumber);
            this.MoveUp(selectedWalletNumber);

            setTimeout(() => {
              if (game.result === 'win') {
                this.win = true
              } else if (game.result === 'error') {
                alert('ВНИМАНИЕ! Произошла ошибка обратитесь к администратору!')
                this.defeat = true
              } else {
                this.defeat = true
              }

              this.game = null;
              this.show = false;
              this.disabled = false
              localStorage.removeItem('currentGame')
            }, 5000)
          }
        })
      }, 2500)
    },
    startTimer() {
      const int = setInterval(() => {
        const now = new Date()
        if (
            this.game &&
            this.game.expired_at &&
            new Date(this.game.expired_at) > now
        ) {
          const timeLeft = (new Date(this.game.expired_at) - now) / 1000
          const mins = Math.floor(timeLeft / 60).toString().replace(/^(\d)$/, '0$1');
          const seconds = Math.floor(timeLeft % 60).toString().substr(0, 2).replace(/^(\d)$/, '0$1');
          this.timer = `${mins}:${seconds}`
        } else {
          this.timer = null
          clearInterval(int)
        }
      }, 20)
    },
    route(params) {
      this.$router.push(params);
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/* Popup */

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(5, 20, 48, 0.8);
  top: 0;
  left: 0;
  z-index: 1000;
}

.popup__body {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup__content {
  background-color: #283d77;
  color: #fff;
  border-radius: 20px;
  max-width: 1000px;
  padding: 60px 192px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.popup__close {
  position: absolute;
  display: block;
  top: 10px;
  right: 10px;
}

.popup__title-win {
  width: 192px;
  height: 24px;
  font-family: PT Root UI;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 30px;
}

img::selection {
  background: transparent;
}

.popup-button {
  display: flex;
  justify-content: center;
}

.btn {
  text-transform: uppercase;
  background: #35756b;
  border-radius: 35px;
  border: 0;
  width: 192px;
  height: 60px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  margin-top: 30px;
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .popup__content {
    padding: 60px 100px;
  }
}

.main {
  flex: 1 1 auto;
  padding-bottom: 60px;
  position: relative;
}

.container {
  /*padding: 0px 15px;*/
  /* max-width: 992px; */
  margin: 0 auto;
}

.header__subtitle {
  font-size: 18px;
  font-family: "PT Root UI";
  font-weight: 400;
  padding-right: 22px;
  text-align: center;
  width: 492px;
  margin: 0 auto;
}

.header__subtitle-text {
  font-family: "PT Root UI";
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
  width: 456px;
  margin: 0 auto;
  padding: 15px 0 15px 0;
}

.timer {
  margin: 10px 0;
}

.timer__text {
  padding: 12px 0;
  width: 162px;
  background: #051430;
  border: 1px solid #283d77;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 60px;
}

.qr {
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 12px;
  position: absolute;
  top: 100px;
}

.qrWrapper {
  max-width: 465px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.qrShow {
  animation: showQr forwards .35s;
}

@keyframes showQr {
  from {
    opacity: 0;
    transform: scale(.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.qr__img {
  width: 80px;
  height: 80px;
}

.btn__copy {
  width: 546px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.copy__address {
  font-family: "PT Root UI";
  line-height: 16px;
  padding: 5px;
  width: 162px;
  height: 48px;
  background: transparent;
  border: 1px solid #2d6960;
  box-sizing: border-box;
  border-radius: 60px;
  display: flex;
  align-items: center;
}

.copy__address:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;
}

.copy__address-text {
  background: url(../assets/img/svg/copy.svg) no-repeat center left;
  padding-left: 24px;
  width: 145px;
  color: #fff;
  text-align: left;
  font-weight: 400;
}

/* Шапка */
.header__logo {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.header__title {
  font-family: 'PT Root UI';
  line-height: 2;
  font-weight: 600;
  font-size: calc((100vw - 320px) / (1280 - 360) * (40 - 20) + 20px);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

/*Контент*/
.images__cups {
  max-width: 516px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 30px;
  z-index: 3;
  position: relative;
}

.images__glow {
  margin-top: -80px;
}

.images__glow-pic {
  max-width: 100%;
}

.images__ball-pic {
  position: relative;
  margin: 0 30px;
}

.images__ball {
  z-index: 2;
  position: relative;
  display: flex;
  max-width: 516px;
  margin: auto;
}

.images__ball.center {
  justify-content: center;
}

.images__ball.left {
  justify-content: flex-start;
}

.images__ball.right {
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .images__ball {
    transform: translate(0, 0);
  }

  .images__cup {
    width: calc((100vw - 320px) / (480 - 320) * (123 - 80) + 60px);
    padding-top: 10px
  }

  .images__ball img {
    width: calc((100vw - 320px) / (480 - 360) * (52 - 40) + 30px);
  }

  .images__cup {
    transform: scale(1.3);
  }
}

@media (max-width: 516px) {
  .images__ball {
    padding: 0 30px;
  }

  .images__ball-pic {
    margin: 0 5vw;
  }
}

.amount {
  padding: 0 15px;
  max-width: 975px;
  margin: 0 auto;
}

.amount__input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.amount__text {
  color: #828a98;
}

.images__cup {
  position: relative;
  top: 0;
  /*Делаю плавную аниммацию*/
  transition: all 0.35s ease 0s;
}

.amount__btn {
  width: 100%;
  text-transform: uppercase;
  background: #35756b;
  border-radius: 35px;
  border: 0;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  padding: 23px;
  transition: all 0.15s ease 0s;
}

.amount__btn:hover {
  background-color: #2c6159;
}

.amount__btn:disabled:hover {
  background-color: #35756b;
}

/*Media запросы*/
@media (max-width: 800px) {
  .amount__input {
    display: block;
  }

  .amount__btn {
    padding: 20px 0;
    margin-top: 12px;
  }

  .amount__text {
    padding-left: 0;
    text-align: center;
  }

  .amount__input {
    margin: 50px 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  .btn__copy {
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }

  .btn__copy {
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 30px;
    z-index: 3;
    position: relative;
  }

  .copy__address-text {
    padding-left: 12px;
    background: none;
  }

  .copy__address {
    width: 115px;
  }

  .header__subtitle {
    width: 345px;
    padding-right: 20px;
  }

  .header__subtitle-text {
    width: 325px;
  }
}

@media (max-width: 518px) {
  .qrWrapper {
    justify-content: space-around;
    max-width: 447px;
  }

  .images__cups {
    margin: 0 30px;
  }
}

.images__glow {
  margin-top: -80px;

}

.images__glow-pic {
  max-width: 100%;
}

.amount {
  padding: 0 15px;
  max-width: 975px;
  margin: 0 auto;
}

.amount__text {
  color: #828A98;
}

.images__cup {
  position: relative;
  top: 0;
  /*Делаю плавную аниммацию*/
  transition: all 0.35s ease 0s;
}

.amount__btn {
  width: 100%;
  text-transform: uppercase;
  background: #35756B;
  border-radius: 35px;
  border: 0;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  padding: 23px;
  transition: all 0.15s ease 0s;
}

.amount__btn:hover {
  background-color: #2c6159;
}

.amount__btn:disabled:hover {
  background-color: #35756B;
}

@media (max-width: 3976px) {
  .images {
    background-image: url(../assets/img/svg/bg-glow.svg);
    background-position: center center;
    background-repeat: no-repeat;
    height: calc((100vw - 320px) / (1500 - 360) * (400 - 200) + 200px);
  }

  .images__glow-pic {
    display: none;
  }
}

@media (min-width: 3976px) {
  .images__ball {
    transform: translate(0px, -75px);
  }
}

.images__cups__000 {
  min-height: 200px;
}

@media (max-width: 500px) {
  .images__cups__000 {
    min-height: 175px;
  }
}

#notifications {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

@keyframes notification {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.notification {
  min-width: 250px;
  font-size: 20px;
  padding: 10px 20px;
  margin-bottom: 20px;
  color: black;
  background-color: #fff;
  text-align: center;
  animation: notification forwards .3s ease-out;
}

.section_transfer_info{
  display: flex;
  justify-content: center;
  width: 100%;
}

.section_transfer_info_text{
  text-align: center;
  color: #828a98;
  width: 70%;
  padding-bottom: 15px;
  font-size: larger;
}

.font__for-adjourned{
  font-size: 20px;
  text-align: center;
}

.font__for-adjourned_link, .font__for-adjourned_link:visited{
  color: white;
  text-decoration: underline;
}
.font__for-adjourned_link:hover{
  text-decoration: none;
}
</style>
