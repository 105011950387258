<template>
  <div class="main-layout">
    <div class="wrapper">
      <div class="container">
        <header class="header">
          <div class="header__logo">
            <img
              class="header__logo-pic"
              src="../assets/img/svg/logo.svg"
              alt="Logo"
            />
          </div>
        </header>
        <h1 class="header__title-text">СТАТИСТИКА ИГРОКОВ</h1>
        <div id="container">
          <div class="bg"></div>
          <div class="search-mobile" style="display: none">
            <div class="search_cont">
              <div class="vvod">
                <input
                  type="text"
                  name="search"
                  class="search_inp"
                  placeholder="Введите id игры, номер кошелька, статус "
                />
              </div>
              <div class="search">
                <div class="search_cont_im">
                  <img
                    src="../assets/img/webStatistics/search-mobile.svg"
                    alt="search"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="first_elem" style="display: none">
            <div class="search_cont">
              <div class="search_cont_im">
                <img
                  src="../assets/img/webStatistics/fi_search.png"
                  alt="search"
                />
              </div>
              <div class="vvod">
                <input
                  type="text"
                  name="search"
                  class="search_inp"
                  placeholder="Введите id игры, номер кошелька, статус "
                />
              </div>
              <div class="search">
                <span class="poisk">поиск</span>
              </div>
            </div>
          </div>
          <table class="content_game">
            <tr>
              <th class="first"><span>ID игры</span></th>
              <th><span>Адрес кошелька пользователя</span></th>
              <th><span>Сумма пари</span></th>
              <th><span>Статус игры</span></th>
              <th class="last"><span>Подробная информация</span></th>
            </tr>
            <tr v-for="game in games" :key="game.id">
              <td v-text="game.id"></td>
              <td v-text="game.player_address"></td>
              <td>{{ game.amount }} DEL</td>
              <td :class="getGameResultClass(game.result)" v-text="getGameResultText(game.result)"></td>
              <td @click="gameInfo = game;open = true"><a class="podrobno">ПОДРОБНО</a></td>
            </tr>
          </table>

          <div class="all_cont">
            <button v-show="hasMore" class="all_result" :disabled="!hasMore" @click="more">
              <img src="../assets/img/webStatistics/rotate.png" alt="rotate" />
              <a>показать еще</a>
            </button>
          </div>
        </div>
      </div>
      <site-modal v-if="gameInfo" v-model="open">
        <div class="game_title">
          Игра №{{ gameInfo.id }}
        </div>

        <div>
          <ul class="game_info--list">
            <li>ID: <span class="game_info--primary" v-text="gameInfo.id"/></li>
            <li>Статус: <span class="game_info--primary" v-text="getGameResultText(gameInfo.result)"/></li>
            <li>Ставка: <span class="game_info--primary" v-text="gameInfo.amount"/></li>
            <li>Кошелек 1: <span class="game_info--primary" v-text="gameInfo.wallet_one.address"/></li>
            <li>Кошелек 2: <span class="game_info--primary" v-text="gameInfo.wallet_two.address"/></li>
            <li>Кошелек 3: <span class="game_info--primary" v-text="gameInfo.wallet_three.address"/></li>
            <li>Кошелек победителя: <span class="game_info--primary" v-text="gameInfo.win_wallet.address"/></li>
            <li>Кошелек игрока: <span class="game_info--primary" v-text="gameInfo.player_address"/></li>
            <li>Дата: <span class="game_info--primary" v-text="new Date(gameInfo.created_at).toLocaleDateString()"/></li>
            <li>Время: <span class="game_info--primary" v-text="new Date(gameInfo.created_at).toLocaleTimeString()"/></li>
          </ul>
        </div>
      </site-modal>
      <site-footer></site-footer>
    </div>
  </div>
</template>

<script>
import SiteFooter from "../components/SiteFooter";
import SiteModal from "../components/SiteModal";
import api from '../mixins/api'

export default {
  name: 'Stats',
  mixins: [api],
  data: () => ({
    loading: false,
    open: true,
    gameInfo: null,
    games: [],
    page: 1,
    lastPage: 2
  }),
  components: {
    SiteFooter,
    SiteModal
  },
  mounted() {
    this.updateGames()
  },
  computed: {
    hasMore() {
      return this.lastPage > this.page
    }
  },
  methods: {
    route(params) {
      this.$router.push(params);
    },
    async updateGames() {
      const {data, last_page, current_page} = await this.loadGames(this.page)
      this.page = current_page
      this.games = this.games.concat(data)
      this.lastPage = last_page
    },
    async more() {
      this.page++
      this.loading = true
      await this.updateGames()
      this.loading = false
    },
    getGameResultClass(result) {
      switch (result) {
        case 'win':
          return 'win'
        case 'defeat':
        case 'overdue':
          return 'defeat'
        default:
          return ''
      }
    },
    getGameResultText(result) {
      switch (result) {
        case 'win':
          return 'Победа'
        case 'defeat':
          return 'Поражение'
        case 'overdue':
          return 'Отмена'
        default:
          return ''
      }
    },
  },
};
</script>

<style scoped>
.game_title {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
}

.game_info--list {
  color: #939EBB;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 30px;
  line-height: 24px;
}

.game_info--list li {
  padding-bottom: 12px;
}

.game_info--primary {
  color: white;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

/*Media запросы*/

.header__logo {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.header__title-text {
  font-family: "Cony";
  font-weight: 600;
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 30px;
  color: #fff;
}

.bg {
  position: absolute;
  left: 341px;
  width: 278px;
  height: 278px;
  margin: 0 auto;
  background: #fff;
  filter: blur(180px);
}
#container {
  position: relative;
  max-width: 850px;
  margin: 0 auto;
}
.first_elem {
  position: relative;
  height: 80px;
  width: 100%;
  background: #051430;
  border: 1px solid #374359;
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
  padding: 0 10px;
}
.search_cont {
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
  max-width: 572px;
  margin: 0 auto;
  background: #051430;
  border: 1px solid #374359;
  box-sizing: border-box;
  border-radius: 30px;
  margin-top: 22px;
}
.search_cont_im img {
  cursor: pointer;
  padding: 10px;
}
.search_cont_im {
  max-width: 36px;
  max-height: 100%;
  border-right: 1px solid #374359;
}
.vvod {
  display: flex;
  left: 48px;
  position: absolute;
  max-width: 416px;
  padding-left: 12px;
  color: white;
}
.search {
  cursor: pointer;
}
.search_inp {
  width: 352px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-family: PT Root UI Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  color: #ffffff;
}
input:focus {
  border: none;
  outline: none;
}
.poisk {
  width: 120px;
  padding: 10px 39px;
  font-family: PT Root UI Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 120px;
  background: #2d6960;
  border: 1px solid #2d6960;
  box-sizing: border-box;
  border-radius: 0 18px 18px 0;
}
table,
th,
td {
  border-collapse: collapse;
}
.content_game {
  position: relative;
  width: 100%;
  max-width: 960px;
  background: #051430;

  box-sizing: border-box;
  border-radius: 20px;
}
.all_result {
  background-color: transparent;
  border: 1px solid #374359;
  display: flex;
  justify-content: center;
}
.all_result a {
  text-decoration: none;
}
.content_game tr {
  height: 60px;
  text-align: center;
}
.content_game th {
  /*width: 20%;*/
  font-family: PT Root UI Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.content_game th span {
  opacity: 0.5;
}
.content_game td {
  width: 20%;
  font-family: PT Root UI Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
td.win {
  font-family: PT Root UI Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #35756b;
}
td.defeat {
  font-family: PT Root UI Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #9a4958;
}
.podrobno {
  cursor: pointer;
  border: 1px solid #2d6960;
  box-sizing: border-box;
  border-radius: 18px;
  padding: 10px 20px;
  font-family: PT Root UI Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.all_cont {
  display: flex;
  padding-top: 40px;
  padding-bottom: 80px;
  justify-content: center;
}
.all_result {
  border: 1px solid #35756b;
  box-sizing: border-box;
  border-radius: 60px;
  padding: 22px 40px;
  width: 192px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;
}
.all_result a {
  margin-left: 5px;
  font-family: PT Root UI bold;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.vvod img {
  cursor: pointer;
}

.frame img {
  max-width: 100%;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th,
table tr td {
  border-right: 1px solid #374359;
  border-bottom: 1px solid #374359;
}
table tr th {
  border-top: solid 1px #374359;
}
table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #374359;
}
table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #374359;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}

@media screen and (max-width: 870px) {
  .container {
    padding: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 767px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .bg {
    display: none;
  }

  .header {
    max-width: 450px;
    margin: 0 auto;
  }

  .header__logo {
    width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .header__title-text {
    white-space: nowrap;
    font-size: 30px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 470px) {
  .search_inp {
    font-size: 11.5px;
  }
  .vvod {
    padding-left: 1px;
  }
}

@media screen and (max-width: 410px) {
  .search_inp {
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) {
  .search_inp {
    font-size: 9.5px;
  }
  .first_elem {
    width: 441px;
  }
}

@media screen and (min-width: 360px) {
  .header__title-text {
    font-size: 20px;
  }
  .search_inp {
    font-size: 8px;
  }
}

@media screen and (max-width: 520px) {
  .all_result {
    width: 100%;
  }
}
.search-mobile {
  display: none;
  margin-bottom: 16px;
}
@media screen and (max-width: 700px) {
  .content_game {
    width: 700px;
  }
  .search-mobile {
    display: block;
  }
  .first_elem {
    display: none;
  }
  .search_cont_im {
    background: #2d6960;
    border: 1px solid #2d6960;
    box-sizing: border-box;
    border-radius: 0 18px 18px 0;
  }
  .vvod {
    position: relative;
  }
  .first {
    border-radius: 20px 0 0 0;
  }
  .last {
    border-radius: 0 20px 0 0;
  }
}
</style>
