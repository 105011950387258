<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
@font-face {
  font-family: "PT Root UI";
  src: url("./assets/fonts/PT-Root-UI_Medium.woff2") format("woff2"),
    url("./assets/fonts/PT-Root-UI_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "PT Root UI";
  src: url("./assets/fonts/PT-Root-UI_Regular.woff2") format("woff2"),
    url("./assets/fonts/PT-Root-UI_Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PT Root UI";
  src: url("./assets/fonts/PT-Root-UI_Bold.woff2") format("woff2"),
    url("./assets/fonts/PT-Root-UI_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Cony";
  src: url("./assets/fonts/ConyDecor.eot");
  src: local("☺"), url("./assets/fonts/ConyDecor.woff") format("woff"),
    url("./assets/fonts/ConyDecor.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	border: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
}
ul,
ol,
li {
	list-style: none;
}
img {
	vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}
</style>
